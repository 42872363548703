import {
  encodeUrlParams,
  getAlertUrlParams
} from "./url-params";
import { getRegionalizedUrl, getRegion, getRegionalizedCognitoUrl, MANAGE_API } from './url-utils';
import Axios from "axios";
import {
  navigate
} from "gatsby";
import * as Sentry from '@sentry/browser';

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user") ?
    JSON.parse(window.localStorage.getItem("user")) : {
      loggedIn: false
    }

export const setUser = user => {
  window.localStorage.setItem("user", JSON.stringify(user))
  try{
    Sentry.configureScope((scope) => {
      scope.setUser({
        "email": user.email,
        "is_domain_admin": user.is_domain_admin,
        "session": user.session,
      });
    });  
  }
  catch(e){
    console.log(e);
  }
}

export const handleLoginRequest = user_email => {
  const params = getAlertUrlParams(window.location);

  let formPayload =  new FormData();
  formPayload.append('user_email', user_email);

  if(params['dep']){
    formPayload.append('dep', params['dep'])
  }
  else if(params['cid'] && params['ep']){
    formPayload.append('cid', params['cid'])
    formPayload.append('ep', params['ep'])
  }

  return Axios({
    method: 'post',
    url: getRegionalizedUrl(MANAGE_API) + '/passwordless-login?v=1',
    data: formPayload,
    crossdomain: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  }).then((response) => {
    console.log(response)
    setUser({
      loggedIn: false,
      user_email
    })
  })
}

export const handleLoginVerify = (user_id, token, cid, ep, dep) => {
  if (!user_id.length || !token.length) {
    return;
  }
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(MANAGE_API) + '/passwordless-verify?v=1',
    data: encodeUrlParams({
      user_id,
      token,
    }),
    crossdomain: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  }).then((response) => {
    console.log(response.data)
    setUser({
      loggedIn: true,
      ...response.data,
    })
    if (dep) {
      navigate(`/alert-details/?dep=${dep}`)
    } else if (cid && ep) {
      navigate(`/alert-details/?cid=${cid}&ep=${ep}`)
    } else {
      navigate('/')
    }
  }).catch((response) => {
    console.log(response)
  })
}

export const validateSession = () => {
  const user = getUser();
  try{
    Sentry.configureScope((scope) => {
      scope.setUser({
        "email": user.email,
        "is_domain_admin": user.is_domain_admin,
        "session": user.session,
      });
    });
  }
  catch(e){
    console.log(e);
  }  
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(MANAGE_API) + '/validate_session?v=1',
    data: encodeUrlParams({
      session: user.session,
      user_email: user.email,
    }),
    crossdomain: true,
  })
}

export const validateSSO = (idToken) => {
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(MANAGE_API) + '/validate-sso',
    data: encodeUrlParams({
      token: idToken
    }),
    crossdomain: true,
  })
}

export const isLoggedIn = () => {
  if (!isBrowser()) return false
  const user = getUser();
  return !!user && user['loggedIn']
}

export const navigateSecure = (to) => {
  navigate(isLoggedIn() ? to : '/login' + window.location.search);
}

export const logout = () => {
  setUser({})
}

export const setRedirectPath = () => {
  window.localStorage.setItem("redirectPath", window.location.pathname + window.location.search);
}

export const getRedirectPath = () => {
  var redirectPath = window.localStorage.getItem("redirectPath");
  if (redirectPath && redirectPath.charAt(0) !== '/') {
    redirectPath = "/" + redirectPath;
  }
  return redirectPath;
}

export const getSSORedirectUrl = (customerId, depString) => {
  let dataParams = depString ? {
    application: 'reporting',
    dep: depString,
    region: getRegion()
  } :
    {
      application: 'reporting',
      identifier: customerId,
      region: getRegion()
    };
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(MANAGE_API) + '/settings/sso',
    data: encodeUrlParams(dataParams),
    crossdomain: true,
  }).then((response) => {
    if (response && response.data && response.data.sso_customers && response.data.sso_customers.length === 1) {
        const ssoData = response.data.sso_customers[0];
        
        return "https://" + getRegionalizedCognitoUrl() + ssoData.cognito_region + ".amazoncognito.com/authorize?idp_identifier=" + ssoData.identifier + "&response_type=token&client_id=" + ssoData.cognito_client_id + "&redirect_uri=" + encodeURIComponent(ssoData.cognito_redirect_url);
    }

    return "";
  })
}