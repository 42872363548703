export function encodeUrlParams(params) {
    return Object.keys(params).map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');
}

export function getLoginUrlParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
        token: searchParams.get('token') || '',
        user_id: searchParams.get('user_id') || '',
    };
}

export function getAlertUrlParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
        cid: searchParams.get('cid') || '',
        ep: searchParams.get('ep') || '',
        dep: searchParams.get('dep') || '',
        action: searchParams.get('action') || '',
    };
}

export function getGatekeeperUrlParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
        m_id: searchParams.get('m_id') || '',
        e_id: searchParams.get('e_id') || '',
    };
}