export const US_EAST_1 = 'us-east-1'
export const US_WEST_2 = 'us-west-2'
export const EU_WEST_2 = 'eu-west-2'
export const US_EAST_2 = 'us-east-2'
export const EU_CENTRAL_1 = 'eu-central-1'
export const CA_CENTRAL_1 = 'ca-central-1'
export const AP_SOUTHEAST_2 = 'ap-southeast-2'
export const AF_SOUTH_1 = 'af-south-1'
export const US = 'us'
export const USB = 'usb'
export const UK = 'uk'
export const DE = 'de'
export const CA = 'ca'
export const AU = 'au'
export const ZA = 'za'
export const STG = "stg"
export const TRACKING_API = 'tracking_api'
export const MANAGE_API = 'manage_api'
export const ARBITER_API = 'arbiter_api'
export const COGNITO_PREFIX = 'codebreaker-frontend.auth.'

export const URL_MAP = {
    [US]: {
        development: {
            [TRACKING_API]: process.env.GATSBY_TRACKING_API_URL,
            [MANAGE_API]: process.env.GATSBY_MANAGE_API_URL,
            [ARBITER_API]: process.env.GATSBY_ARBITER_API_URL
        },
        staging: {
            [TRACKING_API]: 'https://dg01-staging.redatatech.com',
            [MANAGE_API]: 'https://manageapi-staging.mailcontrol.net',
            [ARBITER_API]: 'http://arbiter-api.messagecontrol.com'
        },
        production: {
            [TRACKING_API]: 'https://dg01.redatatech.com',
            [MANAGE_API]: 'https://manageapi.mailcontrol.net',
            [ARBITER_API]: 'http://arbiter-api.messagecontrol.com'
        },

    },
    everything_else: {
        staging: {
            [TRACKING_API]: 'api.getmessagecontrol.com',
            [MANAGE_API]: 'manage-api.getmessagecontrol.com',
        },
        production: {
            [TRACKING_API]: 'api.getmessagecontrol.com',
            [MANAGE_API]: 'manage-api.getmessagecontrol.com',
        },

    },
}

export const API_REGIONS = {
    [US] : US_EAST_1,
    [USB] : US_WEST_2,
    [UK] : EU_WEST_2,
    [STG] : US_EAST_2,
    [DE] : EU_CENTRAL_1,
    [CA] : CA_CENTRAL_1,
    [AU] : AP_SOUTHEAST_2,
    [ZA] : AF_SOUTH_1
}

export const API_REGIONS_INVERSE = {
    [US_EAST_1] : US,
    [US_WEST_2] : USB,
    [EU_WEST_2] : UK,
    [US_EAST_2] : STG,
    [EU_CENTRAL_1] : DE,
    [CA_CENTRAL_1] : CA,
    [AP_SOUTHEAST_2] : AU,
    [AF_SOUTH_1] : ZA
}

export function getRegionalizedUrl(api) {
    let region = getRegion()
    if (region === US) {
        return URL_MAP[US][process.env.GATSBY_URL_ENV][api]
    }
    return 'https://' + API_REGIONS[region] + '.' + URL_MAP['everything_else'][process.env.GATSBY_URL_ENV][api]
}

export function getRegionalizedCognitoUrl() {
    let region = getRegion()
    if (ZA === region) {
        return 'za-redirect-' + COGNITO_PREFIX;
    }
    return COGNITO_PREFIX;
}

export function getRegion() {
    // e.g. manage.messagecontrol.com v. us-east-1.manage.messagecontrol.com
    let frontendDomainList = window.location.hostname.split('.')
    let frontendRegion = frontendDomainList[0].toString().toLowerCase()
    // This covers case e.g. manage.messagecontrol.com
    let region = US
    // If length === 4 we know it looks more like this us-east-1.manage.messagecontrol.com
    if (frontendDomainList.length === 5 || frontendDomainList.length === 4) {
        if (frontendRegion in API_REGIONS) {
            // uk.adcon.cybergraph.mimecast.com
            region = frontendRegion
        } else if (frontendRegion in API_REGIONS_INVERSE) {
            // eu-west-2.manage.messagecontrol.com
            region = API_REGIONS_INVERSE[frontendRegion]
        } else {
            // Should not reach here, but use US as a fallback just in case
            region = US
        }
    }
    return region
}
